export class Form {
  private $delete_image_ids;

  constructor() {
    this.$delete_image_ids = [];
    $('input[type="file"].c-inputFile').on('change', (el: any) => {
      this.previewImage(el)
    })

    $('input[type="file"].c-inputFiles').on('change', (el: any) => {
      this.previewImages(el)
    })

    $('#image_previews').on('click', '.delete', (el: any) => {
      const image_id = $(el.currentTarget).attr('data-id')

      if (image_id != undefined) {
        this.$delete_image_ids.push(image_id)
        $('#product_delete_image_ids').val(this.$delete_image_ids)

        $(el.currentTarget).parent().remove()
      }
    })

    $(".js-searchable").select2({
      language: "ja",
      multiple: true,
      tags: true,
    })

    $(".js-searchable-single").select2({
      language: "ja",
      multiple: false,
      tags: false,
    })
    
    $('form').on('click', '.c-multiFieldArea__removelink', (el) => {
      $(el.currentTarget).parent().prev('input[type=hidden]').val('1');
      $(el.currentTarget).closest('fieldset').hide();
      event.preventDefault();
    })

    $('form').on('click', '.c-multiFieldArea__addlink', (el) => {
      const time = new Date().getTime()
      const regexp = new RegExp($(el.currentTarget).data('id'), 'g')
      $(el.currentTarget).before($(el.currentTarget).data('fields').replace(regexp, time))
      event.preventDefault()
    })
  }

  previewImage(el: any) {
    const file = el.target.files[0];
    const target_class = $(el.target).attr('data-target');
    const load_image_callback = (fragment) => {
      $(`#${target_class}`).html(fragment);
    }
    this.loadImage(file, target_class, 'preview_template', load_image_callback);
    $('#thumbnailFieldLabel').text('ファイルを変更');
  }

  previewImages(el: any) {
    const files = el.target.files;
    const target_class = $(el.target).attr('data-target');
    Array.from(files).forEach((file: any) => {
      const load_image_callback = (fragment) => {
        $(`#${target_class}`).append(fragment);
      }
      this.loadImage(file, target_class, 'image_preview_template', load_image_callback);
      $(`#${target_class} .new`).remove();
    })
    $('#imagesFieldLabel').text('ファイルを変更');
  }

  loadImage(file: any, target_class: string, template_id: string, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const image = new Image();

    reader.onload = (event: any) => {
      image.src = event.target.result;

      image.onload = () => {
        const content = ($(`#${template_id}`)[0]).content
        const fragment = document.createDocumentFragment()
        let clone = document.importNode(content, true);
        let icon = $('i:not(.far)', clone)
        let box = $('div.c-productFormSection__thumbnail', clone)
        let img = $('img', clone)
        box.prop({ class: 'c-productFormSection__thumbnail new' })

        const aspect_ratio_str = icon.attr('data-aspect-ratio-str')
        img.prop({ src: event.target.result });

        if (this.isVertical(image.naturalWidth, image.naturalHeight, aspect_ratio_str)) {
          icon.prop({ class: '_vertical_' })
        }

        fragment.appendChild(clone)
        callback(fragment)

      }
    }
  }

  isVertical(width: number, height: number, aspect_ratio_str: string | undefined) {
    const REGEX = /^\d+x\d+$/g;

    if (aspect_ratio_str == undefined || aspect_ratio_str.match(REGEX) == null) {
      return width < height
    } else {
      const aspect_ratio = aspect_ratio_str.split('x').map(x => Number(x))
      return width * aspect_ratio[1] < height * aspect_ratio[0]
    }
  }
}