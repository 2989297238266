export function copyURL() {
  const element = document.createElement('input');
  element.value = location.href;
  document.body.appendChild(element);
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
  alert('URLをコピーしました');
}

