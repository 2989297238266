import { throttle } from '../functions/throttle';

class Devise {
  readonly $window:any = $(window);
  private deviceType:string = '';
  readonly breakPointMinPC:number = 1188;
  readonly breakPointMinTB:number = 641;
  readonly userAgent:string = navigator.userAgent;

  constructor() {
    this.$window.on('resize', throttle(this.deviceCheck.bind(this), 100));
    this.deviceCheck();
  }

  deviceCheck(): void {
    const oldDeviceType:string = this.deviceType;
    const newDeviceType:string = this.getDeviceType();
    if ( oldDeviceType != newDeviceType ) {
      // console.log('Devise width: '+oldDeviceType+' -> '+newDeviceType);
      this.$window.trigger('change-devise', [oldDeviceType, newDeviceType]);
    }
  }

  getDeviceType(): string {
    if ( this.isPC() ) this.deviceType = 'PC';
    if ( this.isTB() ) this.deviceType = 'TB';
    if ( this.isSP() ) this.deviceType = 'SP';
    return this.deviceType;
  }
  isPC(): boolean { return innerWidth >= this.breakPointMinPC; }
  isTB(): boolean { return innerWidth < this.breakPointMinPC && innerWidth >= this.breakPointMinTB; }
  isSP(): boolean { return innerWidth < this.breakPointMinTB; }
  isNotPC(): boolean { return !this.isPC(); }
  isNotTB(): boolean { return !this.isTB(); }
  isNotSP(): boolean { return !this.isSP(); }

  isAndroid(): boolean { return this.userAgent.indexOf("Android") > 0; }
  isIOS(): boolean { return this.userAgent.indexOf("iPhone") > 0 || this.userAgent.indexOf("iPad") > 0; }
}

export const devise = new Devise();


