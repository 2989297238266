import { isXMLDoc } from "jquery";

export class Evaluation {
  private product_id: number;

  constructor(modal: any) {
    this.product_id = Number(location.pathname.split('/').slice(-1)[0]);

    $('body').on('submit', '#evaluation_form', (el: any) => {
      el.preventDefault()
      const fd = new FormData($(el.currentTarget).get(0));
      $.ajax({
        url: `/async/products/${this.product_id}/evaluations`,
        type: 'POST',
        dataType: 'script',
        processData: false,
        contentType: false,
        data: fd,
        timeout: 5000,
      }).done((result) => {
        if (result.error) {
        } else {
          if($('.c-modal__flash').hasClass('u-d-none')) {
            modal.close()
          }
        }
      });
      return false;
    });
  }
}