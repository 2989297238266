export class Notification {
  private page_num: number;

  constructor() {
    this.page_num = 2;

    $(".c-notifications__more").on('click', () => {
      $.ajax({
        url: '/mypage/async/notifications',
        type: 'GET',
        dataType: 'script',
        data: { page: this.page_num },
        timeout: 5000,
      })
        .done((data) => {
          this.page_num += 1;
        });
    });
  }
}