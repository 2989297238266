import { loadStripe, StripeElementStyle, StripeElement } from '@stripe/stripe-js';

export class StripeOperator {
  private $cardNumber: StripeElement;

  constructor() {
    this.setStripe()
  }

  async setStripe() {
    if ($('#credit_card_form').length != 0) {
      const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
      this.$cardNumber = this.setStripeForm(stripe)
      this.setHandleCreditCard(stripe);
    }
  }

  async submitCreditCard(stripe) {
    $('#credit_card_form').off();
    await stripe.createToken(this.$cardNumber).then((result) => {
      if (!(result.error)) {
        $('#credit_card_card_token').val(result.token.id);
        $('#credit_card_form').submit()
      }
      this.setHandleCreditCard(stripe);
    })
  }

  setHandleCreditCard(stripe) {
    $('#credit_card_form').on('submit', async (el: any) => {
      el.preventDefault()
      await this.submitCreditCard(stripe);
    })
  }

  setStripeForm(stripe: any) {
    const style: StripeElementStyle = {
      base: {
        fontSize: '19px'
      },
    };

    let elements = stripe.elements();

    const cardNumberElement = elements.create('cardNumber', { style, showIcon: true });
    const cardExpiryElement = elements.create('cardExpiry', { style });
    const cardCvcElement = elements.create('cardCvc', { style });

    cardNumberElement.mount('#cardNumberArea');
    cardExpiryElement.mount('#cardExpiryArea');
    cardCvcElement.mount('#cardCvcArea');

    return cardNumberElement;
  }
}