export class Header {
  private $body:JQuery;

  constructor() {
    this.$body = $('body');
    this.$body
    .on('click', '[data-js-toggle-navigation]', this.toggleNavigation.bind(this))
    .on('click', '[data-js-toggle-mypage-header]', this.toggleMypageHeader.bind(this))
    .on('click', '[data-js-toggle-notifications]', this.toggleNotifications.bind(this))
    .on('click', this.toggleSearch.bind(this));
  }

  toggleNavigation(e:any): void {
    const $button:JQuery = $('[data-js-toggle-navigation]');
    const $navigation:JQuery = $('[data-js-navigation]');
    const isOpened:Boolean = $button.hasClass('_opened_');
    if ( isOpened ) {
      $button.removeClass('_opened_');
      $navigation.removeClass('_opened_');
    } else {
      $button.addClass('_opened_');
      $navigation.addClass('_opened_');
    }
  }

  toggleSearch(e:any): void {
    const $button:JQuery = $('[data-js-toggle-search]');
    const $search:JQuery = $('[data-js-search]');
    const $searchCard:JQuery = $('[data-js-search-card]');
    const $searchInput:JQuery = $('[data-js-search-input]');
    const isOpened:Boolean = $button.hasClass('_opened_');

    if ( isOpened ) {
      // 開いているとき
      if(!$(e.target).closest($searchCard).length) {
        $button.removeClass('_opened_');
        $search.removeClass('_opened_');
      }
    } else {
      // 開いていないとき
      if ($(e.target).closest($button).length) {
        $button.addClass('_opened_');
        $search.addClass('_opened_');
        $searchInput.focus();
      }
    }
  }

  toggleMypageHeader(e:any): void {
    const isHeaderOpened:Boolean = this.$body.hasClass('_headerOpened_');
    if ( isHeaderOpened ) {
      this.$body.removeClass('_headerOpened_');
    } else {
      this.$body.addClass('_headerOpened_');
    }
  }

  toggleNotifications(e:any): void {
    const $notifications:JQuery = $('[data-js-notifications]');
    const isNotificationsOpened:Boolean = $notifications.hasClass('_opened_');
    if ( isNotificationsOpened ) {
      $notifications.removeClass('_opened_');
    } else {
      $notifications.addClass('_opened_');
    }
  }
}



