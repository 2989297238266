import { devise } from '../ui/devise';

export class GasCard {
  readonly animateDuration: number;
  private $card: JQuery;
  private $toggleExcerpt: JQuery;
  private $excerpt: JQuery;
  private isExcerptOpened: boolean;
  private isVertical: boolean;
  private excerptHeight!: number;
  readonly excerptCollapseHeight: number;

  constructor( el:any ) {
    this.animateDuration = 300;
    this.excerptCollapseHeight = 70;
    this.$card = $(el);
    this.$toggleExcerpt = this.$card.find('[data-js-toggle-excerpt]');
    this.$excerpt = this.$card.find('[data-js-excerpt]');
    this.isExcerptOpened = false;
    this.isVertical = this.$card.hasClass('_vertical_');

    $(window).on('change-devise', this.initExcerpt.bind(this));
    this.initExcerpt();
  }

  initExcerpt(): void {
    this.excerptHeight = Number(this.$excerpt.height());
    if (devise.getDeviceType() == 'SP' || devise.getDeviceType() == 'TB' || this.isVertical) {
      this.$toggleExcerpt.off().on('click', this.toggleExcerpt.bind(this));
      if (  this.excerptHeight > this.excerptCollapseHeight ) {
        this.closeExcerpt();
      } else {
        this.openExcerpt();
      }
    } else {
      this.isExcerptOpened = this.resetExcerpt()
    }
  }

  resetExcerpt(): boolean {
    this.$toggleExcerpt.off().attr('style', '').removeClass('_opened_');
    return false;
  }

  openExcerpt(): boolean {
    this.$toggleExcerpt.css({ maxHeight: this.excerptHeight + 'px' }).addClass('_opened_');
    return true;
  }

  closeExcerpt(): boolean {
    this.$toggleExcerpt.css({ maxHeight: this.excerptCollapseHeight + 'px' }).removeClass('_opened_');
    return false;
  }

  toggleExcerpt(e:any): void {
    if ( this.isExcerptOpened ) {
      this.isExcerptOpened = this.closeExcerpt();
    } else {
      this.isExcerptOpened = this.openExcerpt();
    }
  }

}
