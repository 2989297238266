import { devise } from '../ui/devise';
import { CopyURLButton } from '../components/CopyURLButton';

export class SideShareButtons {
  private $el: JQuery;
  private $body: JQuery;
  private $openButton: JQuery;
  private copyURLButton;

  constructor() {
    this.copyURLButton = new CopyURLButton();
    this.$el = $('.c-sideShareButtons');
    this.$body = this.$el.find('.c-sideShareButtons__body');
    this.$openButton = $('[data-js-toggle-share]');

    this.$openButton.on('click', this.toggle.bind(this));
  }

  toggle(): void {
    if (devise.isNotPC()) {
      if (this.$el.hasClass('_opened_')) {
        this.close();
      } else {
        this.open();
      }
    }
  }
  open(): void {
    this.$el.addClass('_opened_');
    this.$body.animate( { width: 'toggle' } );
  }
  close(): void {
    this.$el.removeClass('_opened_');
    this.$body.animate( { width: 'toggle' } );
  }

}

