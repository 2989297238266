export function throttle(fn:any, delay:number) {
  let timerId:any;
  let lastExecTime:number = 0;
  return function(this:any) {
    const context:any = this;
    const args:any = arguments;
    let elapsedTime:number = performance.now() - lastExecTime;
    const execute = function() {
      fn.apply(context, args);
      lastExecTime = performance.now();
    }
    if (!timerId) {
      execute();
    }
    if (timerId) {
      clearTimeout(timerId);
    }
    if (elapsedTime > delay) {
      execute(); 
    } else {
      timerId = setTimeout(execute, delay);
    }
  }
}