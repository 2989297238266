import { devise } from '../ui/devise';

export class HomeBannerSlick {
  private $viewer: JQuery;

  constructor() {
    this.$viewer = $('.c-home__bannersSlider');

    if ( this.$viewer[0] ) {
      this.$viewer.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        variableWidth: true,
        dots: true,
      });
    }
  }

}

