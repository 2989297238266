import { FlashMessage } from '../ui/FlashMessage';

class Modal {
  private $container: JQuery;
  private $stage: JQuery;
  private $openButton: JQuery;
  private $closeButton: JQuery;

  constructor() {
    this.$container = $('.c-modalContainer');
    this.$stage = $('.c-modalContainer__stage');
    this.$openButton = $('[data-js-open-modal]');
    this.$closeButton = this.$container.find('[data-js-close-modal]');

    this.$openButton.on('click', this.openHandler.bind(this));
    this.$container
    .on('click', this.closeHandler.bind(this))
  }

  openHandler( e:any ): void {
    let $currentTarget: JQuery;
    let modalHtml: string = '';
    if ( e != undefined ) {
      $currentTarget = $(e.currentTarget);
      modalHtml = $('#'+$currentTarget.attr('data-js-open-modal')).html();
    }
    this.open( modalHtml );
  }
  open( modalHtml:string ): void {
    this.$stage.html( modalHtml ).promise().done(()=> {
      this.$stage.find('.c-flashMessage').each( (index, el)=> { new FlashMessage(el) } );
      this.$container.addClass('_opened_');
    });
  }
  closeHandler( e:any ): void {
    let $target: JQuery;
    if ( e != undefined ) {
      $target = $(e.target);
      if ( $target.is('.c-modalContainer__stage, [data-js-close-modal]') ) {
        this.close();
      }
    } else {
      this.close();
    }
  }
  close(): void {
    // let $currentTarget = $(e.currentTarget);
    this.$container.removeClass('_opened_').promise().done(()=> {
      this.$stage.html('');
    });
  }

}

export const modal = new Modal();