import { copyURL } from '../functions/copyURL';

export class CopyURLButton {
  private $button: JQuery;

  constructor() {
    this.$button = $('[data-js-copy-url]');
    this.$button.on('click', copyURL);
  }

}

