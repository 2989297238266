import { devise } from '../ui/devise';

export class FlashMessage {
  private $el: JQuery;
  private $closeButton: JQuery;
  private closestLayout: string;

  constructor( el:any ) {
    this.$el = $(el);
    this.$closeButton = this.$el.find('[data-js-close-flash-message]');
    this.closestLayout = '.l-page__attention, .l-mypage__attention, .c-modal__flash';

    this.$closeButton.on('click', this.close.bind(this));
  }

  close(): void {
    const $layout:JQuery = this.$el.closest(this.closestLayout);
    // .l-page__attention 内
    if( $layout.length > 0 ) {
      // 自分含め複数ある場合
      if ( $layout.find('.c-flashMessage').length > 1 ) {
        this.$el.remove();
      }
      // それ以外（自分のみの場合）
      else {
        $layout.remove();
      }
    }
    // それ以外（ラッパーレイアウトがない場合）
    else {
      this.$el.remove();
    }
  }

}

