'use strict';

import { devise } from './ui/devise';
import { FlashMessage } from './ui/FlashMessage';
import { MySlick } from './ui/MySlick';
import { modal } from './ui/modal';
import { Header } from './components/Header';
import { SideShareButtons } from './components/SideShareButtons';
import { GasCard } from './components/GasCard';
import { Form } from './components/Form';
import { Notification } from './components/Notification';
import { Evaluation } from './components/Evaluation';
import { User } from './components/User';
import { HomeBannerSlick } from './components/HomeBannerSlick';
import { StripeOperator } from './components/StripeOperator';
import 'select2'
import 'slick-carousel'

// let UI:Object;
// let Components:Object;

// TypeScript的には無作法だけど。。グローバルからの参照を作る。（外部からwindowで参照できる）

declare global {
  interface Window {
    UI: any;
    Components: any;
  }
}
$(() => {
  // スマホ版で :active をアクティベート
  $('body').on('touchstart', () => { });

  // window.UI で参照できる
  window.UI = {
    devise: devise,
    flashMessages: (() => {
      let flashMessages: any[] = [];
      $('.c-flashMessage').each((index, el) => { flashMessages.push(new FlashMessage(el)); });
      return flashMessages;
    })(),
    slicks: (() => {
      let slicks: any[] = [];
      $('[data-js-slick]').each((index, el) => { slicks.push(new MySlick(el)); });
      return slicks;
    })(),
    modal: modal,
  };

  // window.Components で参照できる
  // 汎用的なパーツではないため厳密にはUIに入らないためこちらに分類
  // モノによっては機能拡張できるよう、スタイルの単位でクラス化している
  window.Components = {
    header: new Header(),
    sideShareButtons: new SideShareButtons(),
    form: new Form(),
    notification: new Notification(),
    evaluation: new Evaluation(modal),
    user: new User(),
    StripeOperator: new StripeOperator(),
    gasCards: (() => {
      let gasCards: any[] = [];
      $('[data-js-gas-card]').each((index, el) => { gasCards.push(new GasCard(el)); });
      return gasCards;
    })(),
    homeBannerSlick: new HomeBannerSlick(),
  };

});




