import { devise } from '../ui/devise';

export class MySlick {
  private $el: JQuery;
  private $viewer: JQuery;
  private $pagePerTotal: JQuery;
  private $prev: JQuery;
  private $next: JQuery;
  private $nav: JQuery;

  constructor(el: any) {
    this.$el = $(el);
    this.$viewer = this.$el.find('[data-js-slick-viewer]');
    this.$pagePerTotal = this.$el.find('[data-js-slick-page-per-total]');
    this.$prev = this.$el.find('[data-js-slick-prev]');
    this.$next = this.$el.find('[data-js-slick-next]');
    this.$nav = this.$el.find('[data-js-slick-nav]');

    this.$viewer.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      // arrows: false,
      prevArrow: this.$prev,
      nextArrow: this.$next,
      // fade: true,
      // asNavFor: '[data-js-slick-nav]'
    });

    this.$viewer.on('afterChange', this.showPage.bind(this));

    this.$nav.on('click', this.goto.bind(this));
  }

  showPage(e: any): void {
    $('.currentPage').text(Number($('.slick-current').attr('data-slick-index')) + 1);
  }

  goto(e: any): void {
    const $currentNav = $(e.currentTarget);
    this.$viewer.slick('slickGoTo', $currentNav.index(), false);
  }

}

