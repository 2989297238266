export class User {

  constructor() {
    $(".j-profileOpner").on('click', (el) => {
      const user_id = $(el.currentTarget).attr('data-target_id');

      $.ajax({
        url: `/async/users/${user_id}`,
        type: 'GET',
        dataType: 'script',
        data: {},
        timeout: 5000,
      })
        .done((data) => {
        });
    });
  }
}